import * as tslib_1 from "tslib";
import * as R from 'ramda';
var defaultResolutions = [
    180,
    360,
    540,
    720,
    900,
    1080,
    1296,
    1512,
    1728,
    1944,
    2160,
    2376,
    2592,
    2808,
    3024,
];
/**
 * Remove falsey values from object.
 */
var removeEmpty = R.pickBy(function (v) { return !!v; });
/**
 * Utility to get numbers from ambiguous types.
 */
var getN = R.ifElse(R.is(Number), R.identity, R.curry(R.flip(parseInt))(10));
/**
 * Utility to get unit of width or resolution
 */
var getUnit = function (data) {
    return data.replace(/\d*(\D+)$/gi, '$1');
};
/**
 * Injects a task string into a Filestack URL
 */
var injectTask = function (base) { return function (task) {
    var url = R.head(base);
    var handle = R.last(base);
    var rest = R.dropLast(1, R.tail(base));
    return [url].concat(rest, [task, handle]);
}; };
var injectFormat = function (format) { return function (arr) {
    if (format) {
        var str = "output=format:" + format;
        return injectTask(arr)(str);
    }
    return arr;
}; };
var injectSecurity = function (security) { return function (arr) {
    if (security) {
        var str = "security=p:" + security.policy + ",s:" + security.signature;
        return injectTask(arr)(str);
    }
    return arr;
}; };
var injectResize = function (arr) { return function (width) {
    var str = "resize=width:" + width;
    return injectTask(arr)(str);
}; };
var getWidth = function (width) { return function (resolution) {
    if (typeof resolution === 'number') {
        return resolution;
    }
    var unit = getUnit(resolution);
    if (unit === 'w') {
        return getN(resolution);
    }
    // Pixel density (2x == 2 * size)
    return R.multiply(getN(width), getN(resolution));
}; };
/**
 * Construct Filestack URL out of CDN base and handle, with optional security
 */
var getCdnUrl = function (base, options) {
    if (options.security) {
        var newBase = injectSecurity(options.security)(base);
        return R.join('/', newBase);
    }
    return R.join('/', base);
};
/**
 * Constructs a srcset attribute for source and img elements.
 * Will use resolution descriptors or pixel densities to construct
 * the proper URLs based on the width of the image.
 */
var makeSrcSet = function (base, options, width, format) {
    if (!width && format) {
        return R.join('/', injectFormat(format)(base));
    }
    var resolutions = R.map(R.ifElse(R.is(Number), function (n) { return n + "w"; }, R.identity), options.resolutions);
    var urls = R.map(R.compose(R.join('/'), injectSecurity(options.security), injectFormat(format), injectResize(base), getWidth(width)), options.resolutions);
    return R.join(', ', R.map(R.join(' '), R.zip(urls, resolutions)));
};
/**
 * Construct src attribute for img element.
 * This may contain a resized URL if a fallback size is provided.
 */
var makeSrc = function (base, fallback, options) {
    var unit = getUnit(fallback);
    if (unit === 'vw') {
        return getCdnUrl(base, options);
    }
    var width = getN(fallback);
    return R.join('/', injectResize(base)(width));
};
/**
 * A source element contains many possible hints for the browser.
 * For each media query + size pair we can construct a source
 * with the proper srcset using the size as the width parameter.
 * For each format a source element can be constructed as well.
 * This means there are (sizes × formats) sources.
 *
 * R.xprod lets us compute the Cartesian product of two lists.
 */
var makeSourcesTree = function (base, options) {
    var makeSource = function (media, width, format) {
        if (!format && media === 'fallback') {
            return undefined;
        }
        return removeEmpty({
            media: media === 'fallback' ? undefined : media,
            sizes: width,
            srcSet: makeSrcSet(base, options, width, format),
            type: format ? "image/" + format : undefined,
            key: options.keys
                ? base[1] + "-" + (media || 'fallback') + "-" + (width || 'auto') + "-" + (format || 'auto')
                : undefined,
        });
    };
    // Handle three cases -- sizes + type, just sizes, just type
    if (!options.sizes && options.formats) {
        return R.map(function (f) { return makeSource(null, null, f); }, options.formats);
    }
    var sources = R.toPairs(options.sizes);
    if (options.formats) {
        sources = R.compose(R.splitEvery(3), R.flatten, R.xprod(sources))(options.formats);
    }
    return R.filter(function (v) { return !!v; }, R.map(R.apply(makeSource), sources));
};
/**
 * Just your basic HTML img element. However we can let the user specify
 * a specific width which will incorporate pixel resolutions options in a srcset.
 */
var makeImgTree = function (base, options) {
    if (options.width) {
        return removeEmpty({
            src: makeSrc(base, options.width, options),
            srcSet: makeSrcSet(base, options, options.width),
            alt: options.alt,
            width: getN(options.width),
        });
    }
    var fallback = options.sizes && options.sizes.fallback;
    return removeEmpty({
        src: fallback ? makeSrc(base, fallback, options) : getCdnUrl(base, options),
        srcSet: options.sizes ? makeSrcSet(base, options, fallback) : undefined,
        alt: options.alt,
        width: options.width,
        sizes: fallback || undefined,
    });
};
/**
 * Represent a picture element as a tree where leaf nodes are attributes
 * of one img element and zero or more source elements.
 *
 * This allows passing the structure into hyperscript-like virtual DOM generators.
 * For example see https://github.com/choojs/hyperx
 */
export var makePictureTree = function (handle, opts) {
    if (typeof handle !== 'string') {
        throw new TypeError('Filestack handle must be a string');
    }
    if (opts && opts.resolutions && opts.resolutions.length) {
        var rUnits = R.map(getUnit, R.filter(R.is(String), opts.resolutions));
        if (!opts.sizes && (R.any(R.is(Number), opts.resolutions) || R.contains('w', rUnits))) {
            throw new Error('You must specify at least one size to use width descriptors');
        }
        if (!opts.width && R.contains('x', rUnits)) {
            throw new Error('You must specify a width to use pixel densities.');
        }
    }
    var options = tslib_1.__assign({ resolutions: opts && opts.width ? ['1x', '2x'] : defaultResolutions, keys: true }, opts);
    var base = ['https://cdn.filestackcontent.com', handle];
    var img = makeImgTree(base, options);
    var tree = { img: img };
    if (options.sizes || options.formats) {
        var sources = makeSourcesTree(base, options);
        tree.sources = sources && sources.length ? sources : undefined;
    }
    return removeEmpty(tree);
};
