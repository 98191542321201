import * as tslib_1 from "tslib";
import * as hyperx_ from 'hyperx';
// Hack to support CommonJS in ES module export
var hyperx = hyperx_.default || hyperx_;
var makeImg = function (renderer, obj) {
    return hyperx(renderer)(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["<img ", ">"], ["<img ", ">"])), obj);
};
var makeSource = function (renderer, obj) {
    return hyperx(renderer)(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["<source ", ">"], ["<source ", ">"])), obj);
};
export var makePicture = function (renderer, obj) {
    var img = makeImg(renderer, obj.img);
    if (obj.sources) {
        return hyperx(renderer)(templateObject_3 || (templateObject_3 = tslib_1.__makeTemplateObject(["<picture>", " ", "</picture>"], ["<picture>", " ", "</picture>"])), obj.sources.map(function (s) { return makeSource(renderer, s); }), img);
    }
    return hyperx(renderer)(templateObject_4 || (templateObject_4 = tslib_1.__makeTemplateObject(["<picture>", "</picture>"], ["<picture>", "</picture>"])), img);
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
