import { createElement } from 'bel';
import { makePictureTree } from './tree';
import { makePicture } from './adapters/DOM';
/**
 * Helper that composes makePictureTree with the DOM adapter for generating
 * actual picture elements.
 */
export var picture = function (handle, opts, renderer) {
    if (renderer) {
        return makePicture(renderer, makePictureTree(handle, opts));
    }
    return makePicture(createElement, makePictureTree(handle, opts));
};
export { makePictureTree };
